import { ElementKey, ProxyProp } from '@/types/component';
import { isFunction } from '@/types/is';
import { MergeProps, PartPartial } from '@/types/utils';
import { isIntrinsicElement } from '@/utils/isIntrinsicElement';
import { logger } from '@/utils/logger';
import { arrayCustomizer, Merge, MergeCustomizer, withCustomizers } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { withSlotInstanceof } from '@/utils/withInstanceofProp';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { mergeWith, omitBy, pickBy, upperFirst } from 'lodash-es';
import React from 'react';
import { TVReturnType, VariantProps } from 'tailwind-variants';
import { isNotUndefined, isUndefined } from 'typesafe-utils';

// @ts-expect-error: shortcut for inferred generics
type AnyTheme = TVReturnType;

type ContextProp = { context?: React.Context<any> };

type HTMLElementFromKey<Element extends ElementKey> = Element extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[Element]
  : never;

type GenericFunctionComponentProps<Element extends ElementKey, Theme extends AnyTheme, Extras = never> = MergeProps<
  React.ComponentPropsWithoutRef<Element>,
  Extras
> &
  React.PropsWithChildren &
  VariantProps<Theme> &
  ProxyProp<Element> &
  ContextProp;

export interface GenericSlotFunction<Element extends ElementKey, Theme extends AnyTheme> extends ProxyProp<Element> {
  <Extras>(
    props: GenericFunctionComponentProps<Element, Theme, Extras> & React.RefAttributes<HTMLElementFromKey<Element>>,
  ): React.ReactNode;
  displayName?: React.FunctionComponent['displayName'];
}

export type GenericSlotRender = (props: {
  element: JSX.Element;
  props: Record<string, any>;
  children: React.ReactNode;
  ref?: React.ForwardedRef<any>;
}) => React.ReactNode;

export type GenericSlotProps<Element extends ElementKey, Theme extends AnyTheme> = {
  theme: Theme;
  slot?: keyof Theme['slots'] | keyof Theme['extend']['slots'];
  render?: GenericSlotRender;
  debug?: boolean;
} & Required<ProxyProp<Element>> &
  ContextProp;

export type GenericSlot = <
  Element extends ElementKey,
  Theme extends AnyTheme,
  InferredElement extends ElementKey = Element extends ProxyProp<infer Proxy> ? Proxy : Element,
>(
  props: GenericSlotProps<Element, Theme>,
) => GenericSlotFunction<InferredElement, Theme>;

// TODO: fix type mismatch for `as` when using extended slot
// @ts-expect-error: type mismatch for `as` when using extended slot
export const GenericSlot: GenericSlot = ({ debug, render, slot, ...generic }) => {
  // eslint-disable-next-line react/display-name
  const Slot: ReturnType<GenericSlot> = React.forwardRef(
    ({ as, children, theme, options: unsafeOptions, ...unsafeProps }, ref) => {
      const props = omitBy(unsafeProps, isUndefined);
      const options = omitBy(unsafeOptions, isUndefined);

      const Element = (as || generic.as || 'div') as ElementKey;
      const isStringElement = typeof Element === 'string';
      const isCustomElement = isStringElement && !isIntrinsicElement(Element);
      const resolvedClass = isCustomElement ? 'class' : 'className';

      // @ts-expect-error: `$$instanceof` is a custom property
      const isExtendedSlot = Element.$$instanceof === Symbol.for('component.slot');

      // @ts-expect-error: `$$instanceof` is a custom property
      const isStandaloneComponent = Element.$$instanceof === Symbol.for('component.standalone');

      const ImplicitContext = (generic.context || React.createContext({})) as React.Context<any>;
      const implicitContext = React.useContext(ImplicitContext);

      const ExplicitContext = (props.context || React.createContext({})) as React.Context<any>;
      const explicitContext = React.useContext(ExplicitContext);

      const { Provider } = ImplicitContext;

      const resolvedTheme = [implicitContext.theme, theme, generic.theme].find(isFunction);

      const variantKeys: string[] = resolvedTheme?.variantKeys ?? [];
      const isVariant = (value: any, key: string) => variantKeys.includes(key);
      const isContext = (value: any, key: string) =>
        isNotUndefined(value) && (key.startsWith('$') || ['context', 'theme', ...variantKeys].includes(key));

      const slotKey = `$${String(slot)}`;

      const relatedProps = {
        ...implicitContext,
        ...implicitContext?.[slotKey],
        ...explicitContext,
        ...explicitContext?.[slotKey],
        ...props,
        ...options,
        ...options?.[slotKey],
      };

      const resolvedRef = ref || relatedProps.ref;
      const resolvedVariants = pickBy(relatedProps, isVariant);

      const resolvedStyles = !(isExtendedSlot || isStandaloneComponent)
        ? ((resolvedTheme()?.[slot || 'base'] || resolvedTheme)?.(resolvedVariants) as string)
        : undefined;

      const resolvedContext = pickBy({ theme, ...explicitContext, ...props, ...options }, isContext);
      const withContextProvider = Object.keys(resolvedContext).length > 0 && !(isExtendedSlot || isStandaloneComponent);

      const resolvedProps = omitBy(
        { ...implicitContext?.[slotKey], ...props, ...options, ...options?.[slotKey] },
        isContext,
      );

      const resolvedElementProps: Record<string, any> = {};

      switch (true) {
        case isExtendedSlot:
          Object.assign(resolvedElementProps, {
            theme: resolvedTheme,
            context: generic.context,
            ...resolvedVariants,
            ...resolvedProps,
          });
          break;
        case isStandaloneComponent:
          Object.assign(resolvedElementProps, {
            theme: resolvedTheme,
            context: generic.context,
            options,
            ...props,
          });
          break;
        default:
          Object.assign(resolvedElementProps, merge({ [resolvedClass]: resolvedStyles }, resolvedProps));
          break;
      }

      let element: React.ReactNode = (
        <Element {...withSafeInnerHTML(children)} {...resolvedElementProps} ref={resolvedRef}>
          {withNonHTMLChildren(children)}
        </Element>
      );

      if (render) {
        element = render({ element, props: resolvedElementProps, children, ref: resolvedRef });
      }

      if (withContextProvider) {
        element = <Provider value={resolvedContext}>{element}</Provider>;
      }

      if (debug) {
        logger.debug({
          Element,
          GenericSlot: { render, slot, generic },
          Slot: { as, children, theme, options, props, ref },
          context: {
            ImplicitContext,
            implicitContext,
            ExplicitContext,
            explicitContext,
          },
          resolved: {
            resolvedTheme,
            resolvedVariants,
            resolvedContext,
            resolvedStyles,
            resolvedProps,
            resolvedElementProps,
          },
          is: {
            isStringElement,
            isCustomElement,
            isExtendedSlot,
            isStandaloneComponent,
          },
        });
      }

      return element;
    },
  );

  if (!Slot.displayName) {
    Slot.displayName = upperFirst(slot?.toString()) || 'Base';
  }

  withSlotInstanceof(Slot);

  return Slot;
};

export type GenericSlotFactoryProps<Theme extends AnyTheme> = {
  theme: Theme;
  context?: React.Context<any>;
  debug?: boolean;
};

type Defined<A, B> = A extends undefined ? B : A;

export const GenericSlotFactory = <FactoryTheme extends AnyTheme>(
  factoryProps: GenericSlotFactoryProps<FactoryTheme>,
) => {
  const context = factoryProps?.context ?? React.createContext({});

  const slot = <Element extends ElementKey, Theme extends AnyTheme = undefined>(
    props: PartPartial<GenericSlotProps<Element, Defined<Theme, FactoryTheme>>, 'theme'>,
  ) =>
    GenericSlot<Element, Defined<Theme, FactoryTheme>>({
      ...factoryProps,
      context,
      ...props,
    });

  return slot;
};

const classMergeCustomizer: MergeCustomizer = (a, b, key) => {
  if (key === 'className' || key === 'class') {
    return tw.merge(a, b);
  }
};

const merge: Merge = (...props) => mergeWith({}, ...props, withCustomizers(classMergeCustomizer, arrayCustomizer));
